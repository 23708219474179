<template>
  <SharedApp
    :navigation="superAdminAppNavigation"
    logo-component="SuperAdminAppLogo"
    @logout-click="logoutClick"
  />
</template>

<script lang="ts">
import { Component, Vue } from 'vue-facing-decorator'
import SharedApp from '@shared/SharedApp.vue'
import { navigation } from '@super-admin-app/src/navigation'
import { AuthApi } from '@super-admin-app/src/api/auth'
import { frontendLogout } from '@shared/utils/auth'

@Component({
  components: { SharedApp }
})
export default class SuperAdminApp extends Vue {
  get superAdminAppNavigation() {
    return navigation
  }

  logoutClick() {
    const confirmButtonText = 'Log out'
    const cancelButtonText = 'Cancel'
    const loadingButtonText = 'Loading...'

    this.$confirm('Are you sure you want to log out?', 'Warning', {
      confirmButtonText,
      cancelButtonText,
      confirmButtonClass: 'text-primary',
      type: 'warning',
      center: true,
      beforeClose: async (action, instance, done) => {
        if (action === 'confirm') {
          instance.confirmButtonLoading = true
          instance.confirmButtonText = loadingButtonText
          try {
            await AuthApi.signOut('admin/auth/sign-out')
            frontendLogout()
            done()
          } catch (e) {
            console.log(e)
          }
          instance.confirmButtonLoading = false
          instance.confirmButtonText = confirmButtonText
        }
        done()
      }
    }).then(async () => {
      await this.$router.push({ name: 'login' })
    }).catch(() => {

    })
  }
}
</script>

<style lang="scss">
@import "@shared/assets/scss/_variables";
@import "@shared/assets/scss/_mixins";

.layout-container {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
  background: $background-color;

  .container {
    overflow: hidden;

    .header {
      height: 60px;
      margin-bottom: 20px;
      margin-top: 10px;
      margin-left: 30px;
      margin-right: 30px;
    }

    .main {
      position: relative;
      overflow: hidden;
      padding: 0 10px;
    }

    .view {
      box-sizing: border-box;
      transition: all 0.4s cubic-bezier(0.55, 0, 0.1, 1);
      backface-visibility: hidden;
      /*-webkit-perspective: 1000px;*/

      & > .el-scrollbar__wrap {
        padding: 0 20px;
      }
    }
    .fade-right-enter-from {
      opacity: 0;
      transform: translate(-30px, 0);
    }
    .fade-right-leave-to {
      opacity: 0;
      transform: translate(30px, 0);
    }
    .fade-left-enter-from {
      opacity: 0;
      transform: translate(30px, 0);
    }
    .fade-left-leave-to {
      opacity: 0;
      transform: translate(-30px, 0);
    }
    .fade-top-enter-from {
      opacity: 0;
      transform: translate(0, 30px);
    }
    .fade-top-leave-to {
      opacity: 0;
      transform: translate(0, -30px);
    }
    .fade-top-in-out-enter-from,
    .fade-top-in-out-leave-to {
      opacity: 0;
      transform: translate(0, 30px);
    }
    .fade-bottom-enter-from {
      opacity: 0;
      transform: translate(0, -30px);
    }
    .fade-bottom-leave-to {
      opacity: 0;
      transform: translate(0, 30px);
    }
    .fade-bottom-in-out-enter-from,
    .fade-bottom-in-out-leave-to {
      opacity: 0;
      transform: translate(0, -30px);
    }

    .fade-enter-from,
    .fade-leave-to {
      opacity: 0;
    }

    .main-out-border {
      &--top-left,
      &--top-right {
        background: linear-gradient($background-color, rgba(230, 235, 241, 0));
        height: 16px;
        position: absolute;
        width: 8px;
        z-index: 1;
        top: 4px;
      }
      &--bottom-left,
      &--bottom-right {
        background: linear-gradient(rgba(230, 235, 241, 0), $background-color);
        height: 16px;
        position: absolute;
        width: 8px;
        z-index: 1;
        bottom: 4px;
      }

      &--top-left,
      &--bottom-left {
        left: 42px;

        &::after {
          content: "";
          height: 5px;
          position: absolute;
          right: -4px;
          top: -4px;
          width: 12px;
          box-shadow: 8px 0px 0px 0px $background-color inset;
          border-top-left-radius: 5px;
        }
      }
      &--top-right,
      &--bottom-right {
        right: 42px;

        &::after {
          content: "";
          height: 5px;
          left: -4px;
          position: absolute;
          top: -4px;
          width: 12px;
          box-shadow: -8px 0px 0px 0px $background-color inset;
          border-top-right-radius: 5px;
        }
      }

      &--bottom-left:after {
        border-radius: 0;
        border-bottom-left-radius: 5px;
      }
      &--bottom-right:after {
        border-radius: 0;
        border-bottom-right-radius: 5px;
      }

      &--bottom-left,
      &--bottom-right {
        &::after {
          top: initial;
          bottom: -4px;
        }
      }
    }
  }

  &.boxed {
    max-width: 1300px;
    margin: 0 auto;
    box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.15), 0px 0px 5px 0px rgba(0, 0, 0, 0.1);
  }

  &.footer-above {
    padding-bottom: 40px;
    position: relative;
  }

  &.content-only {
    .container {
      .main-out-border--top-left,
      .main-out-border--top-right,
      .main-out-border--bottom-left,
      .main-out-border--bottom-right {
        display: none;
      }
    }
  }
}

html:not(.ie) {
  .layout-container {
    .container {
      max-width: 1920px;
    }
  }
}

@media (min-width: 1920px) {
  .layout-container:not(.boxed) {
    &.column {
      .container {
        margin: 0 auto;
      }
    }
  }
}

@media (max-width: 768px) {
  .layout-container {
    .container {
      /*width: 100%;
max-width: 100%;
height: 100%;
max-height: 100%;*/

      .header {
        height: 50px;
        background: #fff;
        box-shadow: 0px -20px 10px 20px rgba(0, 0, 0, 0.25);
        margin: 0;
        margin-bottom: 5px;

        .toggle-sidebar {
          box-shadow: none !important;
        }

        .search-box {
          & > .el-autocomplete {
            box-shadow: none !important;
          }
        }
      }

      .main {
        padding: 0 5px;
      }

      .view {
        max-width: 100%;

        & > .el-scrollbar__wrap {
          padding: 0 15px;
          padding-top: 15px;
        }
      }
      .main-out-border--top-left,
      .main-out-border--top-right,
      .main-out-border--bottom-left,
      .main-out-border--bottom-right {
        display: none;
      }
    }
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease-out;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
